/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default [
    {
        url: "/",
        name: "Home",
        slug: "home",
        icon: "HomeIcon"
    },
    {
        url: "/users",
        name: "Users",
        slug: "users",
        icon: "UsersIcon"
    },
    {
        url: null,
        name: "Car Management",
        slug: "car-management",
        icon: "HardDriveIcon",
        index: "7",
        submenu: [
            {
                url: "/brands",
                name: "Brands",
                slug: "brands"
            },
            {
                name: "Car Models",
                slug: "models",
                url: "/models"
            },
            {
                url: "/categories",
                name: "Car Categories",
                slug: "categories"
            },
            {
                url: "/adds-on",
                name: "Adds On",
                slug: "adds-on"
            },
            {
                url: null,
                name: "Specs",
                slug: "specs",
                i18n: "specs",
                submenu: [
                    {
                        url: "/specs-categories",
                        name: "Spec Categories",
                        slug: "specs-categories"
                    },
                    {
                        url: "/specs",
                        name: "Specs Details",
                        slug: "specs"
                    }
                ]
            },
            {
                url: "/spare-parts",
                name: "Spare Parts",
                slug: "spare-parts",
                icon: "PackageIcon"
            }
        ]
    },
    {
        url: null,
        name: "Content Management",
        slug: "content-management",
        icon: "FileTextIcon",
        submenu: [
            {
                url: "/about-us",
                name: "About Us",
                slug: "about-us"
            },
            {
                url: "/branches",
                name: "Branches",
                slug: "branches",
                icon: "MapIcon"
            },
            {
                url: "/dashboard-signs",
                name: "Dashboard Signs",
                slug: "dashboard-signs",
                icon: "SunIcon",
                i18n: "dashboard-signs"
            },
            {
                url: "/extended-warranty",
                name: "Extended Warranty",
                slug: "extended-warranty",
                icon: "CheckSquareIcon"
            },
            {
                url: "/galleries",
                name: "Galleries",
                slug: "galleries",
                icon: "ImageIcon"
            },
            {
                url: "/news",
                name: "News",
                slug: "news",
                icon: "FileTextIcon"
            }
        ]
    },
    {
        url: null,
        name: "RSA",
        slug: "rsa",
        icon: "CompassIcon",
        i18n: "rsa",
        submenu: [
            {
                url: "/rsa",
                name: "Information",
                slug: "rsa",
                icon: "NavigationIcon"
            },
            {
                url: "/rsa-requests",
                name: "Requests",
                slug: "rsa-requests",
                icon: "NavigationIcon"
            },
            {
                url: "/rsa/settings",
                name: "Settings",
                slug: "rsa/settings",
                icon: "SlidersIcon"
            }
        ]
    },
    {
        url: null,
        name: "Trade In",
        slug: "trade-in",
        icon: "RefreshCwIcon",
        i18n: "trade-in",
        submenu: [
            {
                url: "/trade-in",
                name: "Requests",
                slug: "trade-in"
            },
            {
                url: "/trade-in/settings",
                name: "Settings",
                slug: "trade-in/settings"
            }
        ]
    },
    {
        url: null,
        name: "Mansour Plus",
        slug: "mansour-plus",
        i18n: "mansour-plus",
        icon: "StarIcon",
        submenu: [
            {
                url: "/mansourplus-informative",
                name: "Information",
                slug: "mansourplus-informative",
                icon: "NavigationIcon",
                i18n: "informative"
            },
            {
                url: "/mansourplus-link",
                name: "Link",
                slug: "mansourplus-link",
                icon: "NavigationIcon",
                i18n: "link"
            }
        ]
    },
    {
        url: null,
        name: "A+",
        slug: "aplus",
        icon: "AwardIcon",
        submenu: [
            {
                url: "/aplus",
                name: "Advance Plus",
                slug: "aplus"
            },
            {
                url: "/aplus-video",
                name: "Edit A+ Video",
                slug: "aplus"
            }
        ]
    },
    {
        url: null,
        name: "Service Appointment",
        slug: "bookings",
        icon: "CalendarIcon",
        submenu: [
            {
                url: "/bookings",
                name: "Requests",
                slug: "bookings"
            },
            {
                url: "/bookings/settings",
                name: "Settings",
                slug: "bookings/settings"
            }
        ]
    },
    {
        url: null,
        name: "Contact Us",
        slug: "contact-us",
        icon: "InboxIcon",
        submenu: [
            {
                url: "/contact-us",
                name: "Requests",
                slug: "contact-us"
            },
            {
                url: "/contact-us/inquiries",
                name: "Inquiries",
                slug: "contact-us/inquiries"
            },
            {
                url: "/contact-us/settings",
                name: "Settings",
                slug: "contact-us/settings"
            },
            {
                url: "/hotlines",
                name: "Hotlines Settings",
                slug: "hotlines-settings"
            }
        ]
    },
    {
        url: "/notifications",
        name: "Notifications",
        slug: "notifications",
        icon: "TargetIcon"
    },
    {
        url: null,
        name: "Test Drive",
        slug: "test-drive",
        icon: "ClockIcon",
        submenu: [
            {
                url: "/test-drive",
                name: "Requests",
                slug: "test-drive"
            },
            {
                url: "/test-drive-settings",
                name: "Settings",
                slug: "test-drive-settings"
            }
        ]
    },
    {
        url: null,
        name: "Settings",
        slug: "settings",
        icon: "SettingsIcon",
        submenu: [
            {
                url: "/home-services",
                name: "Home Services",
                slug: "home-services"
            },
            {
                url: "/installment",
                name: "Installment",
                slug: "installment",
                icon: "ActivityIcon"
            }
        ]
    },
    {
        url: "/admins",
        name: "Admins",
        slug: "admins",
        icon: "UserCheckIcon"
    },
    {
        url: "/servers",
        name: "Servers",
        slug: "servers",
        icon: "Server"
    },
    /*
  These last 2 items are as padding-bottom
   */
    {
        url: "/requests-logs",
        name: "Requests Logs",
        slug: "requests-logs",
        icon: "ActivityIcon"
    },
    {
        url: "",
        name: "",
        slug: "",
        icon: ""
    }
];
