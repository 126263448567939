var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-nav-menu-item",class:[
        { 'h-nav-active-item text-primary font-medium': _vm.activeLink },
        { 'disabled-item pointer-events-none': _vm.isDisabled }
    ]},[(_vm.to)?_c('router-link',{class:[
            { 'router-link-active': _vm.activeLink },
            'nav-link flex items-center'
        ],attrs:{"exact":"","to":_vm.to,"target":_vm.target}},[(!_vm.featherIcon)?_c('vs-icon',{attrs:{"icon-pack":_vm.iconPack,"icon":_vm.icon}}):_c('feather-icon',{class:_vm.iconClasses,attrs:{"icon":_vm.icon}}),_vm._t("default")],2):_c('a',{staticClass:"nav-link flex items-center",attrs:{"target":_vm.target,"href":_vm.href}},[(!_vm.featherIcon)?_c('vs-icon',{attrs:{"icon-pack":_vm.iconPack,"icon":_vm.icon}}):_c('feather-icon',{class:_vm.iconClasses,attrs:{"icon":_vm.icon}}),_vm._t("default")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }